import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import SEO from "src/components/global/SEO.js"
import ContextConsumer from "src/layouts/Context.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import Companies from "src/components/global/Companies/Companies"
// import InstaFeed from "src/components/global/InstaFeed.js"
import Person from "../components/about/Person"
import Wrapper from "src/components/global/Wrapper"
// import Crop from "src/components/global/Crop"

import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import BodyText from "src/components/global/typography/BodyText.js"

const Team_Claim = styled(BigTextH3)`
  margin-bottom: 50px;
`

const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Company_Claim = styled(BigTextH3)`
  width: 66.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const Bottom_Claim = styled(BigTextH3)`
  width: 91.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const Values_Section = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Value_Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
  &.noMarginBottom {
    margin-bottom: 0px;
  }
`

const Values_Wrapper = styled.div`
  width: 75%;

  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
`

const Values_Number = styled.span`
  font-size: ${typographySizes.xl}px;
  color: ${colors.darkGrey};
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.xl}px;
    width: 100%;
    margin-bottom: 20px;
    display: none;
  }
`

const Values_Content = styled.div`
  width: 62.5%;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 80%;
  }
`

const Value_Btn_Wrapper = styled.div`
  margin-left: calc(37.5% - 52px);
  @media (max-width: ${mediaQuery.desktop}) {
    margin-left: calc(20% - 52px);
    /* width: calc(80% - 52px); */
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20%;
    /* width: 80%; */
  }
  @media (max-width: ${mediaQuery.mobile}) {
    margin-left: 0%;
    width: 100%;
  }
`

const Value_Title = styled(BigTextH3)`
  margin-bottom: 20px;
`

class About extends React.Component {
  state = { person: 500 }
  render() {
    return (
      <>
        <SEO
          noImg
          title={this.props.data.strapiAboutPage.SEO.SEO_Title}
          description={this.props.data.strapiAboutPage.SEO.SEO_Description}
          image={
            this.props.data.strapiAboutPage.SEO.Seo_Img.localFile.publicURL
          }
        />
        <Navigation
          lang={this.props.pageContext.locale}
          langUrl={this.props.pageContext.langUrl}
        />
        <Wrapper>
          <Bottom_Claim>
            {noHangers(this.props.data.strapiAboutPage.Bottom_Claim)}
          </Bottom_Claim>
        </Wrapper>

        <Values_Section>
          <SmallTitle>
            {this.props.data.strapiAboutPage.Values_Title}
          </SmallTitle>

          <Values_Wrapper>
            {/* <Crop> */}
            <Value_Wrapper>
              <Values_Number>1</Values_Number>
              <Values_Content>
                <Value_Title>
                  {this.props.data.strapiAboutPage.Values_First_Title}
                </Value_Title>
                <BodyText>
                  {noHangers(
                    this.props.data.strapiAboutPage.Values_First_Description
                  )}
                </BodyText>
              </Values_Content>
            </Value_Wrapper>

            <Value_Wrapper>
              <Values_Number>2</Values_Number>
              <Values_Content>
                <Value_Title>
                  {this.props.data.strapiAboutPage.Values_Second_Title}
                </Value_Title>
                <BodyText>
                  {noHangers(
                    this.props.data.strapiAboutPage.Values_Second_Description
                  )}
                </BodyText>
              </Values_Content>
            </Value_Wrapper>

            <Value_Wrapper className="noMarginBottom">
              <Values_Number>3</Values_Number>
              <Values_Content>
                <Value_Title>
                  {this.props.data.strapiAboutPage.Values_Third_Title}
                </Value_Title>
                <BodyText>
                  {noHangers(
                    this.props.data.strapiAboutPage.Values_Third_Description
                  )}
                </BodyText>
              </Values_Content>
            </Value_Wrapper>
            <Value_Btn_Wrapper>
              <MainBtn
                link={
                  this.props.pageContext.locale == "pl"
                    ? "/kontakt"
                    : "/en/contact"
                }
                hoverText={this.props.data.strapiAboutPage.Values_Btn.Hover}
                Mobile={this.props.data.strapiAboutPage.Values_Btn.Mobile}
              >
                {this.props.data.strapiAboutPage.Values_Btn.Main}
              </MainBtn>
            </Value_Btn_Wrapper>
            {/* </Crop> */}
          </Values_Wrapper>
        </Values_Section>
        <Wrapper>
          <Company_Claim>
            {noHangers(this.props.data.strapiAboutPage.Company_Claim)}
          </Company_Claim>
        </Wrapper>
        <Companies lang={this.props.pageContext.locale} />
        <ContextConsumer>
          {({ data, set }) => (
            <Wrapper id="ludzie">
              <SmallTitle>
                {noHangers(this.props.data.strapiAboutPage.Team_Title)}
              </SmallTitle>

              <Team_Claim>
                {noHangers(this.props.data.strapiAboutPage.Team_Claim)}
              </Team_Claim>
              <TeamWrapper>
                {this.props.data.allStrapiPeople.edges.map(
                  (document, index) => (
                    <div>
                      {document.node.Draft_working_article ? (
                        <Person
                          key={index}
                          Index={index}
                          Open={index === data.person ? true : false}
                          Img={
                            document.node.Img.localFile.childImageSharp.fluid
                          }
                          Name={document.node.Name}
                          Claim={noHangers(document.node.Main_Claim)}
                          Description={noHangers(document.node.Paragraph)}
                          Links={document.node.Btn}
                          Company={document.node.company.Title}
                        />
                      ) : null}
                    </div>
                  )
                )}
              </TeamWrapper>
            </Wrapper>
          )}
        </ContextConsumer>
        <Footer lang={this.props.pageContext.locale} />
      </>
    )
  }
}

export const PoznajNasQuery = graphql`
  query PoznajNasQuery($locale: String!) {
    strapiAboutPage(locale: { eq: $locale }) {
      SEO {
        SEO_Description
        SEO_Title
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Team_Claim
      Team_Title
      Bottom_Claim
      Company_Claim
      Values_First_Description
      Values_First_Title
      Values_Second_Description
      Values_Second_Title
      Values_Third_Description
      Values_Third_Title
      Values_Title
      Values_Btn {
        Hover
        Main
        Mobile
      }
    }
    allStrapiPeople(
      sort: { order: ASC, fields: Name }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Img {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 500
                  traceSVG: { background: "#ffffff", color: "#ffff00" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          Btn {
            Hover
            Main
            Mobile
            Link
          }
          Main_Claim
          Name
          Paragraph
          Draft_working_article
          company {
            Title
          }
        }
      }
    }
  }
`

export default About
