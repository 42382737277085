import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const BigTextH3 = styled.h3`
  font-size: ${typographySizes.xl}px;
  color: ${colors.darkGrey};
  line-height: 1.1;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

export default BigTextH3
