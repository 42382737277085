import { createGlobalStyle } from "styled-components"
import colors from "src/assets/styles/colors.js"

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: "GT-Walsheim", sans-serif !important;
        /* font-family: "Favorit-Light"; */
        color: ${colors.darkGrey};
        font-weight: lighter;
    }
    ::selection {
        color: ${colors.darkGrey};
        background: ${colors.mainColor};
;
}
 body{
     overflow-x: hidden;
 }
 strong {
      background-color: ${colors.mainColor};
    }
 html{
     /* overflow-x: hidden; */
 }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`

export default GlobalStyle
