import React, { useRef } from "react"

import styled from "styled-components"
// import PinCustom from "src/assets/images/map-pin.png"
import mediaQuery from "src/assets/styles/mediaQuery"

import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps"

const mapStyles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "##000000",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#f72121",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry",
    stylers: [
      {
        lightness: "100",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#f3f3f3",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "100",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [
      {
        lightness: "100",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "off",
      },
      {
        lightness: "23",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#953131",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        weight: "0.93",
      },
      {
        lightness: "-15",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#ffd900",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#cccccc",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
]

const MainWrapper = styled.div`
  width: 100%;
  height: 35vw;
  margin-bottom: 1px;
  position: relative;
  /* a[href^="http://maps.google.com/maps"],
  a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"]
  {
    display: none !important;
  } */
  .gm-bundled-control .gmnoprint {
    display: block;
  }
  .gmnoprint:not(.gm-bundled-control) {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 45vw;
  }
`

function MapOne({ lat, lng }) {
  const mapRef = useRef(null)

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={16}
      defaultCenter={{
        lat: lat,
        lng: lng,
      }}
      defaultOptions={{ styles: mapStyles }}
    >
      <Marker
        position={{
          lat: lat,
          lng: lng,
        }}
      />
    </GoogleMap>
  )
}

const MapWrapped = withScriptjs(withGoogleMap(MapOne))

export default function App({ lat, lng }) {
  return (
    <MainWrapper>
      <MapWrapped
        lat={lat}
        lng={lng}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAi0LrZ7N8vjd7-7HqbVk0XvPyHU4OBFrE`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </MainWrapper>
  )
}
