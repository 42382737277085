import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql } from "gatsby"
import Tilt from "react-tilt"
// import VizSensor from "react-visibility-sensor"
// import ContextConsumer from "src/layouts/Context.js"
import Img from "gatsby-image"
import slugify from "slugify"
import SEO from "src/components/global/SEO.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"

import distances from "src/assets/styles/distances.js"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import Div100vh from "react-div-100vh"

import Wrapper from "src/components/global/Wrapper"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import ArrowLink from "src/components/global/ArrowLink.js"
import ClientsLink from "src/components/home/ClientsLink.js"
import ScrollDown from "src/components/home/ScrollDown.js"
// import InstaFeed from "src/components/global/InstaFeed.js"
import MainBtn from "src/components/global/btn/MainBtn"
// import Crop from "src/components/global/Crop"
import ShortNewsSection from "src/components/global/ShortNewsSection.js"

const changeColor = keyframes`
  from {
    color: ${colors.darkGrey}
  }

  to {
    color: ${colors.white}
  }
`

const Wraper = styled.div`
  transition: all 1.5s;

  .Tilt-inner {
    background-color: red;
    height: 400px;
  }

  /* &.color {
    background-color: #000;
    transition: all 1.5s;
    p,
    h1,
    h2,
    h3,
    div,
    a,
    span {
      animation: ${changeColor} 1.5s forwards;
    }
    .ArrowBody {
      background-color: #fff;
    }
    .ArrowHead {
      border: solid #fff;
      border-width: 0 3px 3px 0;
    }
    .show .ArrowBody {
      background-color: ${colors.mainColor};
    }
    .show .ArrowHead {
      border: solid ${colors.mainColor};
      border-width: 0 3px 3px 0;
    }
  } */
`

const Header = styled.header`
  height: 100%;
  margin: 0 ${distances.mainBigMargin}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  /* &::before {
    position: absolute;
    content: "";
    top: 30vh;
    left: 5vw;
    width: 80vw;
    height: 20vh;
    transform: rotate(-5deg);
    background-color: ${colors.mainColor};
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 0 ${distances.smallMargin}px;
    overflow: hidden;
  }
`

const HeaderTitleWrapper = styled.div`
  position: relative;
  left: 58.33%;
  width: 380px;
  font-weight: lighter;
  bottom: 50px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 62px);
    left: 0%;
    bottom: 50px;
  }
`

const HeaderTitle = styled.h1`
  font-size: ${typographySizes.xl}px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

const HeaderImg = styled(Img)`
  height: 12.5vw;
  width: 9vw;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 27vw;
    width: 20vw;
  }
`

const StyledTilt = styled(Tilt)`
  position: absolute;
  ${HeaderImg} {
    box-shadow: 0px 2px 52px 20px rgba(0, 0, 0, 0);
    transition: all 0.5s;
    transform: scale(1);
  }
  &:hover {
    z-index: 999;
    ${HeaderImg} {
      box-shadow: 0px 10px 52px 20px rgba(0, 0, 0, 0.4);
      transition: all 0.5s;
      transform: scale(1.5);
    }
  }
  &:nth-of-type(1) {
    left: 10%;
    top: 52%;
    z-index: 20;
  }
  &:nth-of-type(2) {
    left: 30%;
    top: 26%;

    @media (max-width: ${mediaQuery.tablet}) {
      top: 23%;
    }
  }
  &:nth-of-type(3) {
    left: 26%;
    bottom: 10%;

    @media (max-width: ${mediaQuery.tablet}) {
      left: 36%;
      bottom: 35%;
    }
  }
  &:nth-of-type(4) {
    left: 2%;
    top: 45%;

    @media (max-width: ${mediaQuery.tablet}) {
      left: 67%;
      top: 20%;
    }
  }
  &:nth-of-type(5) {
    left: 17%;
    top: 15%;

    @media (max-width: ${mediaQuery.tablet}) {
      left: 38%;
      top: 7%;
    }
  }
  &:nth-of-type(6) {
    left: 10%;
    top: 30%;
  }
  &:nth-of-type(7) {
    left: 39%;
    top: 39%;

    @media (max-width: ${mediaQuery.tablet}) {
      left: 59%;
      top: 39%;
    }
  }
  &:nth-of-type(8) {
    left: 1%;
    bottom: 5%;

    @media (max-width: ${mediaQuery.tablet}) {
      left: 70%;
      bottom: 35%;
    }
  }
  &:nth-of-type(9) {
    left: 18%;
    top: 50%;
    @media (max-width: ${mediaQuery.tablet}) {
      left: 14%;
      top: 45%;
    }
  }
  &:nth-of-type(10) {
    left: 0%;
    top: 15%;
  }
  &:nth-of-type(11) {
    left: 15%;
    bottom: 0%;
    @media (max-width: ${mediaQuery.tablet}) {
      left: 0%;
      bottom: 25%;
    }
  }
`

const CompetencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

class IndexPage extends React.Component {
  state = { sectionViz: false }

  changeColors = isVisible => {
    this.setState({
      sectionViz: isVisible,
    })
  }
  render() {
    const DataHome = this.props.data.strapiHome
    const DataCompetences = this.props.data.allStrapiCompetences.edges
    const DataProjects = this.props.data.allStrapiProjects.edges

    return (
      <>
        <SEO
          title={DataHome.SEO.SEO_Title}
          description={DataHome.SEO.SEO_Description}
          image={DataHome.SEO.Seo_Img.publicURL}
        />

        <Wraper className={this.state.sectionViz ? "color" : ""}>
          <Navigation
            lang={this.props.pageContext.locale}
            langUrl={this.props.pageContext.langUrl}
          />
          <Div100vh>
            <Header>
              <HeaderTitleWrapper>
                {/* <Crop> */}
                <HeaderTitle>{DataHome.Header_Title}</HeaderTitle>
                {/* </Crop> */}
              </HeaderTitleWrapper>
              {DataHome.Images_max_8.map(document => (
                <StyledTilt
                  className="Tilt"
                  options={{ max: 35, reverse: "false" }}
                >
                  <HeaderImg
                    loading="eager"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "50% 50%",
                    }}
                    fluid={document.localFile.childImageSharp.fluid}
                  />
                </StyledTilt>
              ))}
              <ScrollDown
                lang={this.props.pageContext.locale == "en" ? "/en/home" : ""}
              />
            </Header>
          </Div100vh>
          <Wrapper id="first-section">
            <SmallTitle>
              {" "}
              {this.props.pageContext.locale !== "en"
                ? "kompetencje"
                : "competencies"}
            </SmallTitle>

            <CompetencesWrapper>
              {DataCompetences.map(document => (
                <ArrowLink
                  link={`${
                    this.props.pageContext.locale !== "en"
                      ? "/corobimy"
                      : "/en/what-we-do"
                  }`}
                  strapiId={document.node.strapiId}
                >
                  {document.node.Name}
                </ArrowLink>
              ))}
            </CompetencesWrapper>
          </Wrapper>
          <Wrapper>
            <SmallTitle>
              {this.props.pageContext.locale !== "en"
                ? "nasi klienci"
                : "our clients"}
            </SmallTitle>
            <ClientsWrapper>
              {DataProjects.map(document => (
                <ClientsLink
                  link={`/${
                    this.props.pageContext.locale === "en"
                      ? "en/projects"
                      : "corobimy"
                  }/${slugify(document.node.Title, {
                    lower: true,
                    strict: true,
                  })}`}
                  competences={document.node.competences}
                >
                  {document.node.Title}
                </ClientsLink>
              ))}
            </ClientsWrapper>
            <MainBtn
              hoverText={`${DataHome.Btn_Clients.Hover}`}
              Mobile={DataHome.Btn_Clients.Mobile}
              link={`/${
                this.props.pageContext.locale === "en"
                  ? "en/what-we-do"
                  : "corobimy"
              }#Project`}
            >
              {DataHome.Btn_Clients.Main}
            </MainBtn>
          </Wrapper>
          <ShortNewsSection
            data={this.props.data}
            lang={this.props.pageContext.locale}
          />{" "}
        </Wraper>

        <Footer lang={this.props.pageContext.locale} />
      </>
    )
  }
}

export const query = graphql`
  query Home($locale: String!) {
    strapiHome(locale: { eq: $locale }) {
      SEO {
        SEO_Description
        SEO_Title
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Header_Title
      Btn_Clients {
        Hover
        Main
        Mobile
      }
      Btn_News {
        Hover
        Main
        Mobile
      }
      Images_max_8 {
        localFile {
          childImageSharp {
            fluid(
              maxWidth: 500
              traceSVG: { background: "#ffffff", color: "#ffff00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allStrapiCompetences(
      sort: { fields: Order, order: ASC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          strapiId
          Name
          locale
        }
      }
    }
    allStrapiProjects(
      filter: { Home_featured: { eq: true }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          Title
          id
          competences {
            Name
          }
        }
      }
    }
    allStrapiOpinionsNews(
      sort: { fields: Date, order: DESC }
      limit: 4
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Title
          id
          Category
          person {
            Name
          }
          Date(formatString: "DD.MM.YY")
        }
      }
    }
  }
`

export default IndexPage
