import React from "react"
import styled from "styled-components"
import Wrapper from "src/components/global/Wrapper"
import NumberBox from "src/components/Project/NumberBox.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const NumbersSectionWrapper = styled.div`
  width: 66.66%;
  margin-left: 25%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: -20px;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-left: 0%;
  }
`

class NumbersSection extends React.Component {
  render() {
    return (
      <Wrapper>
        <NumbersSectionWrapper>
          {this.props.Content.map(document => (
            <NumberBox document={document} />
          ))}
        </NumbersSectionWrapper>
      </Wrapper>
    )
  }
}

export default NumbersSection
