import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import slugify from "slugify"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const Arrow = styled.div`
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  right: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  pointer-events: none;
`

const ArrowHead = styled.div`
  border: solid ${colors.darkGrey};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: scale(1) rotate(-45deg);
  transition: all 0.5s;
  height: 2px;
  width: 2px;
`

const ArrowBody = styled.div`
  height: 3px;
  width: 25px;
  background-color: ${colors.darkGrey};
  transform: translate(10px, 0);
  transition: all 0.5s;
`
const ArrowWraper = styled.div`
  position: relative;
  transition: all 0.5s;
  z-index: -1;
  width: 70px;
  &.show ${Arrow} {
    left: 50%;
    top: 50%;
    transform: translate(25%, -50%);
    opacity: 0;
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    /* opacity: 1; */
  }
  &.show ${ArrowHead} {
    transition: all 0.5s;
    border: solid ${colors.mainColor};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: scale(1) rotate(-45deg);
    transition: all 0.5s;
    height: 2px;
    width: 2px;
  }
  &.show ${ArrowBody} {
    width: 25px;
    transition: all 0.5s;
    background-color: ${colors.mainColor};
  }
`

const ArrowBig = styled.div`
  position: absolute;
  transform: translate(-200%, -40%);
  /* opacity: 0; */
  left: 50%;
  top: 50%;
  display: flex;
  z-index: 0;
  align-items: center;
  transition: all 0.5s;
  &.show {
    transform: translate(calc(-50% - 55px), -40%);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    /* opacity: 1; */
  }
`

const ArrowHeadBig = styled.div`
  border: solid ${colors.white};
  border-width: 0 25px 25px 0;
  display: inline-block;
  padding: 25px;
  transform: scale(0) rotate(-45deg);
  transition: all 0.5s 0.2s;
  &.show {
    transform: scale(1) rotate(-45deg);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const ArrowBodyBig = styled.div`
  height: 25px;
  width: 0px;
  background-color: ${colors.white};
  transform: translate(54px, 0);
  transition: all 0.3s;
  &.show {
    width: 155px;
    transition: all 1s 0.2s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const Wrapper = styled(Link)`
  width: 100%;
  display: none;
  margin-bottom: 60px;
  text-decoration: none;
  position: relative;
  flex-wrap: wrap;
  transition: background-color 1s;
  &.show {
    background-color: ${colors.mainColor};
    transition: background-color 1s;
  }
  &.active {
    display: flex;
  }
`

const StyledImg = styled(Img)`
  width: calc(25% - 20px);
  height: calc((100vw - 100px) * 0.17);
  margin-right: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 60vw;
    margin-bottom: 20px;
    margin-right: 0;
  }
  img {
    transform: scale(1);
    object-fit: cover;
    background-color: #fff;
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33) !important ;
  }
  &.show {
    img {
      transform: scale(1.15);
      transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33) !important ;
    }
  }
`

const RightCol = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const Top = styled.div`
  width: 100%;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.h2`
  font-size: ${typographySizes.xl}px;
  position: relative;
  text-decoration: none;
  line-height: 1;
  padding: 5px 0;
  @media (max-width: ${mediaQuery.desktop}) {
    font-size: ${typographySizes.l}px;
    padding: 4px 0;
  }
`
const CompetenceWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`

const Competence = styled.span`
  margin-right: 20px;
  font-size: ${typographySizes.s}px;
`

class ProjectCard extends React.Component {
  state = { hoverON: false, hoverOFF: false, x: 0 }

  hoverOn = () => {
    this.setState({
      hoverON: true,
      hoverOFF: false,
    })
  }

  hoverOff = () => {
    this.setState({
      hoverON: false,
      hoverOFF: true,
    })
  }
  render() {
    let isActive = "false"

    if (this.props.active === "all") {
      isActive = "true"
    } else {
      this.props.competences.map(document =>
        document.Name === this.props.active ? (isActive = "true") : null
      )
    }

    return (
      <Wrapper
        // to={`/${this.props.locale}/corobimy/${slugify(this.props.title, {
        //   lower: true,
        // })}`}
        to={`/${
          this.props.locale === "en" ? "en/projects" : "corobimy"
        }/${slugify(this.props.title, {
          lower: true,
          strict: true,
        })}`}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        className={`${this.state.hoverON ? "show" : null} ${
          isActive === "true" ? "active" : null
        }`}
      >
        <StyledImg
          loading="eager"
          className={this.state.hoverON ? "show" : null}
          fluid={this.props.img}
        ></StyledImg>
        <RightCol>
          <ArrowBig className={this.state.hoverON ? "show" : null}>
            <ArrowBodyBig className={this.state.hoverON ? "show" : null} />
            <ArrowHeadBig className={this.state.hoverON ? "show" : null} />
          </ArrowBig>
          <Top>
            <Title>{this.props.title}</Title>
            <ArrowWraper className={this.state.hoverON ? "show" : null}>
              <Arrow>
                <ArrowBody className={"ArrowBody"} />
                <ArrowHead className={"ArrowHead"} />
              </Arrow>
            </ArrowWraper>
          </Top>
          <CompetenceWrapper>
            {this.props.competences.map(document => (
              <Competence>{document.Name}</Competence>
            ))}
          </CompetenceWrapper>
        </RightCol>
      </Wrapper>
    )
  }
}

export default ProjectCard
