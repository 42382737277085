import React from "react"
import styled, { keyframes } from "styled-components"
import colors from "src/assets/styles/colors.js"
import distances from "src/assets/styles/distances.js"

import { Link } from "gatsby"

const Arrow = styled.div`
  /* top: 30%; */
  /* left: 50%; */
  height: 50px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  pointer-events: none;
  transform: rotate(90deg) scale(2) translate(-8px, 0%);
  opacity: 1;
  transition: all 0.5s;
  cursor: pointer;
  &.hover {
    opacity: 1;
    transition: all 0.5s;
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.darkGrey};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: scale(1) rotate(-45deg);
  transition: all 0.5s;
  height: 2px;
  width: 2px;
`

const move = keyframes`
  from {
    transform: rotate(90deg) scale(2) translate(-8px, 0%);
  }

  to {
    transform: rotate(90deg) scale(2) translate(17px, 0%);
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  bottom: ${distances.mainBigMargin}px;
  right: 0;
  height: 50px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
  :hover ${Arrow} {
    animation: ${move} 1s 0.2s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  /* &:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 20px;
    z-index: 10;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &:after {
    content: "";
    z-index: 10;

    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  } */
`

const ScrollDown = props => (
  <StyledLink to={`${props.lang}/#first-section`} aria-label="scroll down">
    <Arrow>
      {/* <ArrowBody /> */}
      <ArrowHead />
    </Arrow>
    <Arrow>
      {/* <ArrowBody /> */}
      <ArrowHead />
    </Arrow>
  </StyledLink>
)

export default ScrollDown
