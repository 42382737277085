import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import distances from "src/assets/styles/distances.js"
import ExternalBtn from "src/components/global/btn/ExternalBtn"
import ContextConsumer from "src/layouts/Context.js"

import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ChangeTextPerson from "src/components/global/typography/changeTextPerson.js"
import BodyText from "src/components/global/typography/BodyText.js"
import MidText from "src/components/global/typography/MidText"

const Wrapper = styled.div`
  margin-bottom: 20px;
  flex-direction: column;
  overflow: hidden;
`

const Top = styled.button`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  @media (max-width: ${mediaQuery.laptop}) {
    align-items: flex-start;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: ${colors.mainColor};
    transition: all 0.5s;
    z-index: -1;
  }
  &:hover::before {
    width: 30%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.active::before {
    width: 100%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const StyledImg = styled(Img)`
  height: 55px !important;
  width: 55px !important;
  margin-right: 20px;
  /* @media (max-width: ${mediaQuery.laptop}) {
    height: 45px !important;
    width: 45px !important;
  } */
`

const Name = styled(MidText)`
  text-align: left;
`

const Content = styled.div`
  margin-top: 50px;
  position: absolute;
  opacity: 0;
  z-index: -1;
  margin-bottom: 70px;
  height: 0;
  overflow: hidden;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 70px;
  }

  &.open {
    position: inherit;
    opacity: 1;
    transition: all 0.5s;
  }
`
const ContentWrap = styled.div`
  position: absolute;
  width: calc(100vw - ${distances.mainBigMargin * 2}px);
  left: 50px;
  overflow: hidden;
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100vw - ${distances.smallMargin * 2}px);
    left: ${distances.smallMargin}px;
  }
`
const Claim = styled(MidText)`
  position: relative;

  margin-bottom: 20px;
  width: 58.33%;
  margin-right: 41.67%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 75%;
    margin-right: 25%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
  }
`

const ContentBottom = styled.div`
  display: flex;
  width: 100%;

  flex-wrap: wrap;
`

const ContentRight = styled.div`
  width: 33.33%;
  margin-left: 8.33%;
  line-height: 1.3;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`

const Description = styled(BodyText)`
  .DescriptionBtn:last-of-type {
    margin-top: 0;
    background-color: red;
  }

  .marginP {
    margin-bottom: 50px;
  }
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    .marginP {
      margin-bottom: 0px;
    }
  }
`

const StyledBigImg = styled(Img)`
  height: 30vw !important;
  width: 25% !important;
  margin-right: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 60vw !important;
    width: 50% !important;
    order: -1;
    margin-bottom: 20px;
  }
`

const XSignVertical = styled.div`
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: ${colors.darkGrey};
  left: 50%;
  top: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  &.active {
    transform: translate(-50%, -50%) rotate(90deg) !important;

    transition: all 1s cubic-bezier(0.17, 0.67, 0, 2);
  }
`

const XSignHorizontal = styled.div`
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: ${colors.darkGrey};
  left: 50%;
  top: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
`

const XSign = styled.div`
  height: 30px;
  width: 30px;
  top: 10px;
  right: 25%;
  position: absolute;
  margin-left: 50px;
  transform: rotate(45deg);
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    transform: rotate(135deg);
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    right: 0%;
  }
`

const Person = props => {
  const [height, setHeight] = useState()
  // const [open, setOpen] = useState(true)
  // const [hover, setHover] = useState(false)
  const [hoverRef, isHovered] = useHover()

  const container = useRef()

  useEffect(() => {
    setHeight(container.current.querySelector(".contentWrap").clientHeight)
  }, [])

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper ref={container}>
          <Top
            onClick={() => {
              set({ person: props.Index })
            }}
            className={props.Open ? " active" : " "}
            ref={hoverRef}
          >
            <StyledImg fluid={props.Img} />
            <Name>
              <ChangeTextPerson hoverText={props.Company} hover={isHovered}>
                {props.Name}
              </ChangeTextPerson>
            </Name>
          </Top>
          <Content
            className={props.Open ? " open" : " "}
            style={{ height: height }}
          >
            <ContentWrap className={"contentWrap "}>
              <ContentBottom>
                <XSign
                  onClick={() => {
                    set({ person: null })
                  }}
                >
                  <XSignVertical />
                  <XSignHorizontal />
                </XSign>
                <Claim>{props.Claim}</Claim>

                <StyledBigImg fluid={props.Img} />
                <ContentRight>
                  <Description>
                    <ReactMarkdown
                      className={"marginP"}
                      source={props.Description}
                    />
                    {props.Links.map(data => (
                      <ExternalBtn
                        // oneText
                        marginTop="0px"
                        hoverText={data.Hover}
                        Mobile={data.Mobile}
                        link={data.Link}
                      >
                        {data.Main}
                      </ExternalBtn>
                    ))}
                  </Description>
                </ContentRight>
              </ContentBottom>
            </ContentWrap>
          </Content>
        </Wrapper>
      )}
    </ContextConsumer>
  )
}

// Description

export default Person
function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener("mouseover", handleMouseOver)
        node.addEventListener("mouseout", handleMouseOut)

        return () => {
          node.removeEventListener("mouseover", handleMouseOver)
          node.removeEventListener("mouseout", handleMouseOut)
        }
      }
    },
    [ref.current] // Recall only if ref changes
  )

  return [ref, value]
}
