import React from "react"
import styled from "styled-components"
import Wrapper from "src/components/global/Wrapper"
import MidText from "src/components/global/typography/MidText"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import BodyText from "src/components/global/typography/BodyText"
import ReactMarkdown from "react-markdown"
import mediaQuery from "src/assets/styles/mediaQuery"

const InformationSectionWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const LeftCol = styled(SmallTitle)`
  width: 16.66%;
  margin-top: 5px;
  text-align: right;
  @media (max-width: ${mediaQuery.laptop}) {
    text-align: left;
    /* white-space: nowrap; */
    width: auto;
    /* width: 100%; */
  }
`

const RightCol = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const Highlighted = styled(MidText)`
  margin-bottom: 50px;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const Paragraph = styled(BodyText)`
  width: 77.77%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0px;
    }
  }
`

const InformationSection = props => (
  <InformationSectionWrapper>
    <LeftCol>{props.Title}</LeftCol>
    <RightCol>
      <Highlighted>
        <ReactMarkdown source={props.Highlighted} />
      </Highlighted>
      <Paragraph>
        <ReactMarkdown
          className={"marginP"}
          source={props.Paragraph}
          renderers={{
            link: props => (
              <a href={props.href} rel="noreferrer" target="_blank">
                {props.children}
              </a>
            ),
          }}
        />
      </Paragraph>
    </RightCol>
  </InformationSectionWrapper>
)

export default InformationSection
