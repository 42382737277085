import React from "react";

function TextWithLineBreaks(props) {
  // Check if props.text is a string
  if (typeof props.text !== "string") {
    return null; // or return <>{props.text}</> to render the content as-is
  }

  // Process the string with line breaks
  const textWithBreaks = props.text.split("\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  return <>{textWithBreaks}</>;
}

export default TextWithLineBreaks;
