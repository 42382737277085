import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import ExternalBtn from "src/components/global/btn/ExternalBtn"
import MainBtn from "src/components/global/btn/MainBtn"
import Wrapper from "src/components/global/Wrapper"
import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const NewBusinessTitle = styled(BigTextH3)`
  width: 66.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const NewBusinessBottom = styled.div`
  /* margin-left: calc(41.66% - 52px); */
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.laptop}) {
    > div {
      /* margin-bottom: 20px; */
    }
  }
`

export default function NewBusinessSection({
  onlyPl,
  claim,
  title,
  briefBtn,
  aboutBtn,
  lang,
}) {
  return (
    <StaticQuery
      query={graphql`
        query NewBusinessSectionN {
          strapiSocialMediaLinks {
            Facebook
            Instagram
            Linkedin
          }
          strapiContactPage {
            New_business_Claim
            New_business_Title
            Person_Img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            Person_Name
            Telephone {
              Hover
              Main
            }
            Claim
            Social_Title
            About_Btn {
              Hover
              Main
              Mobile
            }
            Brief_btn_ {
              Hover
              Main
              Mobile
              Link
            }

            What_We_Do_Btn {
              Hover
              Main
              Mobile
            }
            Recruitment_Claim
            Recruitment_Title
            Recruitment_Btn {
              Hover
              Main
              Mobile
            }
          }
        }
      `}
      render={data => (
        <Wrapper>
          <SmallTitle>{title}</SmallTitle>
          <NewBusinessTitle>{claim}</NewBusinessTitle>
          <NewBusinessBottom>
            <ExternalBtn
              marginTop="50px"
              hoverText={briefBtn.Hover}
              Mobile={briefBtn.Mobile}
              link={briefBtn.Link}
            >
              {briefBtn.Main}
            </ExternalBtn>
            {onlyPl != true && (
              <MainBtn
                // oneText
                link="/corobimy#Project"
                marginTop="0px"
                hoverText={data.strapiContactPage.What_We_Do_Btn.Hover}
                Mobile={data.strapiContactPage.What_We_Do_Btn.Mobile}
              >
                {data.strapiContactPage.What_We_Do_Btn.Main}
              </MainBtn>
            )}
            <MainBtn
              // oneText
              marginTop={onlyPl == true ? "0px" : "0px"}
              link={lang == "pl" ? "/poznajnas/" : "/en/about-us"}
              hoverText={aboutBtn.Hover}
              Mobile={aboutBtn.Mobile}
            >
              {aboutBtn.Main}
            </MainBtn>
          </NewBusinessBottom>
        </Wrapper>
      )}
    />
  )
}
