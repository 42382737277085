import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ChangeTextBtn from "src/components/global/typography/changeTextBtn"
// import ReactTextTransition, { presets } from "react-text-transition"
import mediaQuery from "src/assets/styles/mediaQuery"

import typographySizes from "src/assets/styles/typographySizes.js"
// import colors from "src/assets/styles/colors.js"

const Wrapper = styled.div`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0px")};
  width: 100%;

  display: inline-block;
  /* font-size: ${typographySizes.l}px; */
  z-index: 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: -15px;

  @media (max-width: ${mediaQuery.tablet}) {
    /* font-size: ${typographySizes.m}px; */
    /* margin-top: 50px; */
  }
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  overflow: hidden;
  font-size: ${typographySizes.l}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : "50px")};
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  .text-transition_inner div {
    text-decoration: underline;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}px;
    display: none;
  }
`

const StyledLinkMobile = styled(StyledLink)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
    margin-top: 50px;
  }
`

class NewsLink extends React.Component {
  state = { hoverON: false, hoverOFF: false, x: 0 }

  hoverOn = () => {
    this.setState({
      hoverON: true,
      hoverOFF: false,
      y: -100,
    })
  }

  hoverOff = () => {
    this.setState({
      hoverON: false,
      hoverOFF: true,
      width: 0,
    })
  }

  render() {
    return (
      <Wrapper
        marginTop={this.props.marginTop}
        marginLeft={this.props.marginLeft}
        className={this.state.hoverON ? "show" : ""}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
      >
        <StyledLinkMobile to={this.props.link} marginTop={this.props.marginTop}>
          {this.props.Mobile}
        </StyledLinkMobile>

        <StyledLink to={this.props.link} marginTop={this.props.marginTop}>
          <ChangeTextBtn
            hoverText={this.props.hoverText}
            hover={this.state.hoverON}
            hoverOFf={this.state.hoverOFF}
            oneText={this.props.oneText}
          >
            {this.props.children}
          </ChangeTextBtn>
        </StyledLink>
      </Wrapper>
    )
  }
}

export default NewsLink
