import React, { useRef, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import noHangers from "src/components/global/fn/noHangers.js"

import styled from "styled-components"
import BodyText from "src/components/global/typography/BodyText.js"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"

const ConditionDescription = styled(BodyText)`
  font-size: ${typographySizes.m}px;
  color: ${colors.darkGrey};
  margin-top: 25px;
  line-height: 1.3;
  overflow: hidden;
  height: 0;
  /* visibility: hidden; */
  transition: all 0.5s ease-out;
  &.active {
    /* background-color: red; */
    margin-bottom: 30px;
  }
  .marginP {
    p {
      margin-bottom: 25px;
    }
  }
`

const Person = props => {
  const [height, setHeight] = useState()

  const container = useRef()

  useEffect(() => {
    setHeight(container.current.querySelector(".marginP").scrollHeight)
  }, [])

  return (
    <>
      <ConditionDescription
        onShow={() =>
          setHeight(container.current.querySelector(".marginP").clientHeight)
        }
        ref={container}
        className={props.Active ? "active" : ""}
        style={props.Active ? { height: height } : null}
      >
        <ReactMarkdown
          className={"marginP"}
          source={noHangers(props.Description)}
        />
      </ConditionDescription>
      {/* <p>{height} </p> */}
    </>
  )
}

export default Person
