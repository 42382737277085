import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import slugify from "slugify"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import NewsLink from "src/components/home/NewsLink.js"
import MainBtn from "src/components/global/btn/MainBtn"
import Wrapper from "src/components/global/Wrapper"

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export default function ShortNewsSection({ data, lang }) {
  return (
    <Wrapper>
      <SmallTitle>
        {lang === "en" ? "news and opinions" : "newsy i opinie"}
      </SmallTitle>
      <NewsWrapper>
        {data.allStrapiOpinionsNews.edges.map(document => (
          <NewsLink
            News
            link={`/${lang === "en" ? "en/news" : "newsyiopinie"}/${slugify(
              document.node.Title,
              {
                lower: true,
                strict: true,
              }
            )}`}
            date={document.node.Date}
            author={document.node.person.Name}
            category={document.node.Category}
          >
            {document.node.Title}
          </NewsLink>
        ))}
      </NewsWrapper>

      <MainBtn
        link={`/${lang === "en" ? "en/news" : "newsyiopinie"}`}
        hoverText={data.strapiHome.Btn_News.Hover}
        Mobile={data.strapiHome.Btn_News.Mobile}
      >
        {data.strapiHome.Btn_News.Main}
      </MainBtn>
    </Wrapper>
  )
}
