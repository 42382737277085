import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"

const Arrow = styled.div`
  position: absolute;
  transform: translate(-200%, -40%);
  /* opacity: 0; */
  left: 50%;
  top: 50%;
  display: flex;
  z-index: 0;
  align-items: center;
  transition: all 0.5s;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.mainColor};
  border-width: 0 25px 25px 0;
  display: inline-block;
  padding: 25px;
  transform: scale(0) rotate(-45deg);
  transition: all 0.5s 0.2s;
`

const ArrowBody = styled.div`
  height: 25px;
  width: 0px;
  background-color: ${colors.mainColor};
  transform: translate(60px, 0);
  transition: all 0.3s;
`

const TextWrap = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-start;
  &.show ${Arrow} {
    transform: translate(calc(-50% - 55px), -40%);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    z-index: 1;
  }
  &.show ${ArrowHead} {
    transform: scale(1) rotate(-45deg);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.show ${ArrowBody} {
    width: 155px;
    transition: all 1s 0.2s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
`

const Wrapper = styled.button`
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 50px;
  }
`

const StyledLink = styled(Link)`
  font-size: ${typographySizes.xl}px;
  text-decoration: none;
  z-index: 99;
  position: relative;
  text-align: left;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

const CompetenceWrapper = styled.div`
  /* width: 0%; */
  display: flex;
  flex-wrap: wrap;
  &.show {
    /* width: 100%; */
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 8px;
  }
`

const Competence = styled.span`
  font-size: ${typographySizes.s}px;
  margin-right: 0px;
  display: inline-block;
  transition: all 0.5s;
  overflow: hidden;
  max-width: 0px;
  opacity: 0;
  width: auto;
  white-space: nowrap;
  transition: max-width 0.5s, opacity 0.5s, margin-right 0.5s !important;
  &.show {
    transition: max-width 1s, opacity 0.75s, margin-right 0.75s !important;
    max-width: 600px;
    margin-right: 20px;
    opacity: 1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &.show {
      margin-right: 20px;
      width: auto;
    }
    transition: max-width 1s, opacity 0.5s 0.5s, margin-right 0.5s !important;
    max-width: 600px;
    margin-right: 20px;
    opacity: 1;
  }
`

class ClientsLink extends React.Component {
  state = { hoverON: false }

  hoverOn = () => {
    this.setState({
      hoverON: true,
    })
  }

  HoverOff = () => {
    this.setState({
      hoverON: false,
    })
  }

  render() {
    return (
      <ContextConsumer>
        {({ data, set }) => (
          <Wrapper
            onMouseEnter={this.hoverOn}
            onMouseLeave={this.HoverOff}
            className={this.state.hoverON ? "show" : ""}
          >
            <CompetenceWrapper className={this.state.hoverON ? "show" : ""}>
              {this.props.competences.map(document => (
                <Competence className={this.state.hoverON ? "show" : ""}>
                  {document.Name}
                </Competence>
              ))}
            </CompetenceWrapper>
            <TextWrap className={this.state.hoverON ? "show" : ""}>
              <Arrow>
                <ArrowBody />
                <ArrowHead />
              </Arrow>
              <StyledLink
                // onClick={() =>
                //   set({ competence: this.props.strapiId, sectionViz: false })
                // }
                to={this.props.link}
              >
                {this.props.children}
              </StyledLink>
            </TextWrap>
          </Wrapper>
        )}
      </ContextConsumer>
    )
  }
}

export default ClientsLink
