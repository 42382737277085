import React from "react"
import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"

const Wrapper = styled.div`
  font-size: ${typographySizes.s}px;
`

const Author_Date = props => (
  <Wrapper>
    <p>
      {props.Date} / {props.Category}
    </p>

    <p>Autor: {props.Name}</p>
  </Wrapper>
)

export default Author_Date
