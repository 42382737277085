import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import distances from "src/assets/styles/distances.js"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"

const NavCompetenceWrapper = styled.nav`
  position: sticky;
  top: 100px;
  z-index: 900;
  @media (max-width: ${mediaQuery.laptop}) {
    /* position: relative; */
    margin-left: 0%;
    bottom: 0;
    background-color: ${colors.white};
    padding: 20px 0;
    width: calc(100% + ${distances.smallMargin}px);
    overflow-y: scroll;
    margin-top: 20px;
  }
`

const NavCompetenceUl = styled.ul`
  list-style: none;
  display: inline-flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: row;
  }
`

const NavCompetenceElement = styled.li`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  text-decoration: underline;
  padding: 7.5px;
  text-transform: lowercase;
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: ${colors.mainColor};
    transition: all 0.5s;
    z-index: -1;
  }
  &:hover::before {
    width: 30%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.active::before {
    width: 100%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.showIndex::before {
    width: 100%;
    transition: all 0.5s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding-right: 10px;
  }
`

const NavCompetence = ({ location, lang }) => {
  const [UrlIndex, setNum] = useState(100000)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (location.hash.split("=")[1]) {
        setNum(location.hash.split("=")[1])
      }
    }
  }, [])
  return (
    <StaticQuery
      query={graphql`
        query NavQuery {
          allStrapiCompetences(sort: { order: ASC, fields: Name }) {
            edges {
              node {
                Name
                strapiId
                locale
              }
            }
          }
        }
      `}
      render={dataQuery => (
        <NavCompetenceWrapper>
          <ContextConsumer>
            {({ data, set }) => (
              <NavCompetenceUl>
                {dataQuery.allStrapiCompetences.edges.map(
                  (document, index) =>
                    lang == document.node.locale && (
                      <NavCompetenceElement
                        onClick={() =>
                          set({ competence: document.node.strapiId })
                        }
                        // className={
                        //   document.node.strapiId === data.competence
                        //     ? "active"
                        //     : null
                        // }
                        className={`${
                          document.node.strapiId === data.competence
                            ? "active"
                            : "hide"
                        } ${
                          document.node.strapiId == UrlIndex &&
                          data.competence == 9999
                            ? "active"
                            : "hide"
                        } ${
                          index === 0 &&
                          data.competence == 9999 &&
                          UrlIndex == 100000
                            ? "showIndex"
                            : null
                        }`}
                      >
                        <Link
                          to={`#CompetenceWrapper?x=${document.node.strapiId}`}
                        >
                          {document.node.Name}
                        </Link>
                      </NavCompetenceElement>
                    )
                )}
              </NavCompetenceUl>
            )}
          </ContextConsumer>
        </NavCompetenceWrapper>
      )}
    />
  )
}

export default NavCompetence
