import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const BodyText = styled.p`
  font-size: ${typographySizes.m}px;
  color: ${colors.darkGrey};
  line-height: 1.5;

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}px;
  }
`

export default BodyText
