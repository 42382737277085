import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import BigTextH3 from "src/components/global/typography/BigTextH3"
import MidText from "src/components/global/typography/MidText"

import mediaQuery from "src/assets/styles/mediaQuery"
import Wrapper from "src/components/global/Wrapper"

import ExternalBtn from "src/components/global/btn/ExternalBtn"

const StyledHeaderImg = styled(Img)`
  width: calc(25% - 20px);
  margin-right: 20px;
  height: 30vw;
  object-fit: cover;
  object-position: center;
  @media (max-width: ${mediaQuery.mobile}) {
    width: calc(50%);
    height: 60vw;
  }
`

const TopHeader = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.mobile}) {
    flex-direction: column;
  }
`

const TopRightHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
    margin-top: 20px;
  }
`

const HeaderPersonName = styled(BigTextH3)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
`

const HeaderClaim = styled(MidText)`
  align-self: flex-end;
  max-width: 590px;
  @media (max-width: ${mediaQuery.laptop}) {
    max-width: 100%;
    align-self: flex-start;
  }
`

const BottomHeader = styled.div`
  margin-left: calc(25% - 52px);
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
  }
`

const PersonContact = props => (
  <Wrapper>
    <TopHeader>
      <StyledHeaderImg fluid={props.Img} />
      <TopRightHeader>
        <HeaderPersonName>{props.PersonName}</HeaderPersonName>
        <HeaderClaim>
          <ReactMarkdown source={props.Claim} />
        </HeaderClaim>
      </TopRightHeader>
    </TopHeader>
    <BottomHeader>
      <ExternalBtn
        marginTop="50px"
        hoverText={props.TelephoneHoverText}
        Mobile={props.TelephoneMobile}
        link={props.TelephoneLink}
      >
        {props.TelephoneMain}
      </ExternalBtn>
      <ExternalBtn
        marginTop="5px"
        hoverText={props.MailHoverText}
        Mobile={props.MailMobile}
        link={props.MailLink}
      >
        {props.MailMain}
      </ExternalBtn>
      {/* <ExternalBtn
        marginTop="5px"
        hoverText={props.AddressHoverText}
        Mobile={props.AddressMobile}
        link={props.AddressLink}
      >
        {props.AddressMain}
      </ExternalBtn> */}
    </BottomHeader>
  </Wrapper>
)

export default PersonContact
