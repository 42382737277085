import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const MidText = styled.h2`
  font-size: ${typographySizes.l}px;
  color: ${colors.darkGrey};
  line-height: 1.2;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.ml}px;
    line-height: 1.3;
  }
`

export default MidText
