import styled from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const SmallTitle = styled.h2`
  font-size: ${typographySizes.s}px;
  margin-bottom: 20px;

  @media (max-width: ${mediaQuery.tablet}) {
    background-color: ${props =>
      props.noColorMobile ? "" : `${colors.mainColor}`};
    /* background-color: ${colors.mainColor}; */
    width: auto;
    display: inline-block;
  }
`

export default SmallTitle
