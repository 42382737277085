import React from "react"
import styled, { keyframes } from "styled-components"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import CountUp from "react-countup"
import VizSensor from "react-visibility-sensor"
import mediaQuery from "src/assets/styles/mediaQuery"

const Wrapper = styled.div`
  width: calc(50% - 10px);
  overflow: hidden;
  background-color: #f3f3f3;
  padding-bottom: calc(50% - 10px);
  margin-bottom: 20px;
  position: relative;
  transition: background-color 0.5s;

  :hover {
    background-color: ${colors.mainColor};
    transition: background-color 0.5s;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
    padding-bottom: 100%;
  }
`

const NumberBoxContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const Title = styled.h4`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGrey};
  margin-bottom: 20px;
`

const Number = styled.span`
  font-size: 90px;
  color: ${colors.darkGrey};
  display: flex;
  align-items: flex-start;
`

const Percent = styled.span`
  font-size: 50px;
  color: ${colors.darkGrey};
`

const move = keyframes`
  from {
    top: 110%;
  }

  to {
    top: -10%
  }
`

const Arrow = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  pointer-events: none;
  transform: rotate(-90deg) scale(5) translate(0, -30%);
  opacity: 0;
  transition: all 0.5s;
  :nth-last-of-type(1) {
    left: 20%;
    animation: ${move} 1.8s linear infinite;
  }
  :nth-last-of-type(2) {
    left: 50%;
    animation: ${move} 2s linear infinite;
  }

  :nth-last-of-type(3) {
    left: 80%;
    animation: ${move} 1.7s linear infinite;
  }
  &.hover {
    opacity: 1;
    transition: all 0.5s;
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.white};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: scale(1) rotate(-45deg);
  transition: all 0.5s;
  height: 2px;
  width: 2px;
`

const ArrowBody = styled.div`
  height: 3px;
  width: 20px;
  background-color: ${colors.white};
  transform: translate(10px, 0);
  transition: all 0.5s;
`

class NumberBox extends React.Component {
  state = { hoverON: false }

  hoverOn = () => {
    this.setState({
      hoverON: true,
    })
  }

  hoverOff = () => {
    this.setState({
      hoverON: false,
    })
  }
  render() {
    return (
      <Wrapper onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff}>
        <CountUp end={this.props.document.Number} start={0} duration={2.75}>
          {({ countUpRef, start }) => (
            <VizSensor onChange={start} delayedCall partialVisibility>
              <NumberBoxContent>
                <Title>{this.props.document.Number_Title}</Title>
                <Number>
                  <span ref={countUpRef} />

                  <Percent>%</Percent>
                </Number>
              </NumberBoxContent>
            </VizSensor>
          )}
        </CountUp>

        <Arrow className={this.state.hoverON ? "hover" : ""}>
          <ArrowBody />
          <ArrowHead />
        </Arrow>
        <Arrow className={this.state.hoverON ? "hover" : ""}>
          <ArrowBody />
          <ArrowHead />
        </Arrow>
        <Arrow className={this.state.hoverON ? "hover" : ""}>
          <ArrowBody />
          <ArrowHead />
        </Arrow>
      </Wrapper>
    )
  }
}

export default NumberBox
