import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import slugify from "slugify"
import NewsLink from "src/components/home/NewsLink.js"
import Wrapper from "src/components/global/Wrapper"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import SEO from "src/components/global/SEO.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

class NewsyiopinieLayout extends React.Component {
  render() {
    const DataNews = this.props.data.allStrapiOpinionsNews.edges
    const localePrefix = this.props.pageContext.locale === "en" ? "en/" : ""
    const localeCatalog =
      this.props.pageContext.locale === "en" ? "news" : "newsyiopinie"
    return (
      <>
        <SEO
          title={this.props.data.strapiNewsPage.SEO?.SEO_Title}
          description={this.props.data.strapiNewsPage.SEO?.SEO_Description}
          image={this.props.data.strapiNewsPage.SEO.Seo_Img.publicURL}
        />
        <Navigation
          lang={this.props.pageContext.locale}
          langUrl={this.props.pageContext.langUrl}
        />

        <Wrapper>
          <SmallTitle>{this.props.data.strapiNewsPage.Title}</SmallTitle>
          <NewsWrapper>
            {DataNews.map(document => (
              <NewsLink
                News
                link={`/${localePrefix}${localeCatalog}/${slugify(
                  document.node.Title,
                  {
                    lower: true,
                    strict: true,
                  }
                )}`}
                date={document.node.Date}
                author={document.node.person.Name}
                category={document.node.Category}
              >
                {document.node.Title}
              </NewsLink>
            ))}
          </NewsWrapper>
        </Wrapper>
        <Footer />
      </>
    )
  }
}

export const NewsyiopinieQuery = graphql`
  query NewsyiopinieQuery($locale: String!) {
    allStrapiOpinionsNews(
      sort: { fields: Date, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Title
          id
          Category
          person {
            Name
          }
          Date(formatString: "DD.MM.YY")
        }
      }
    }
    strapiNewsPage(locale: { eq: $locale }) {
      SEO {
        SEO_Description
        SEO_Title
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Title
    }
  }
`

export default NewsyiopinieLayout
