import React from "react"
import styled from "styled-components"
import ChangeTextBtn from "src/components/global/typography/changeTextBtn"
// import ReactTextTransition, { presets } from "react-text-transition"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const Wrapper = styled.div`
  margin-top: ${props => (props.marginTop ? props.marginTop : "50px")};
  display: inline-block;
  font-size: ${typographySizes.l}px;
  z-index: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: -15px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}px;
    margin-top: 50px;
  }
  /* border-bottom: 1px solid ${colors.darkGrey}; */
`

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
  .text-transition_inner div {
    text-decoration: underline;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}px;
    display: inline-block;
    @media (max-width: ${mediaQuery.tablet}) {
      display: none;
    }
  }
`

const StyledLinkMobile = styled(StyledLink)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
  }
`
class ExternalBtn extends React.Component {
  state = { hoverON: false, hoverOFF: false, x: 0 }

  hoverOn = () => {
    this.setState({
      hoverON: true,
      hoverOFF: false,
      y: -100,
    })
  }

  hoverOff = () => {
    this.setState({
      hoverON: false,
      hoverOFF: true,
      width: 0,
    })
  }

  render() {
    return (
      <Wrapper
        marginTop={this.props.marginTop}
        className={this.state.hoverON ? "show" : ""}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
      >
        <StyledLinkMobile href={this.props.link} target="blank">
          {this.props.Mobile}
        </StyledLinkMobile>

        <StyledLink href={this.props.link} target="blank">
          <ChangeTextBtn
            hoverText={this.props.hoverText}
            hover={this.state.hoverON}
            hoverOFf={this.state.hoverOFF}
            oneText={this.props.oneText}
          >
            {this.props.children}
          </ChangeTextBtn>
        </StyledLink>
      </Wrapper>
    )
  }
}

export default ExternalBtn
