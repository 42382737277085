import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import distances from "src/assets/styles/distances.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import Wrapper from "src/components/global/Wrapper"
import Swipeable from "react-swipeable"

const Credits = styled.p`
  font-size: ${typographySizes.s}px;
  color: ${colors.darkGray};
  padding: 5px;
  background-color: ${colors.white};
  position: absolute;
  left: 5px;
  bottom: 0;
  opacity: 0;
  margin-left: ${props =>
    props.sliderWidth
      ? `calc(100% - ${props.sliderWidth}% + ${distances.mainBigMargin / 2}px )`
      : "0%"};
  transform: translate(0, 100%);
  transition: all 0.5s;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0px;
    opacity: 1;
    position: relative;
    left: 0px;
    margin-left: 0;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  width: ${props =>
    props.sliderWidth
      ? `calc(${props.sliderWidth}% + ${distances.mainBigMargin}px)`
      : `calc(100% + ${distances.mainBigMargin}px)`};
  margin-left: ${props =>
    props.sliderWidth ? `calc(100% - ${props.sliderWidth}% )` : "0%"};

  @media (max-width: ${mediaQuery.tablet}) {
    width: ${`calc(100% + ${distances.smallMargin}px)`};
    margin-left: 0;
  }

  figcaption {
    margin-top: 10px;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const StyledImg = styled(Img)`
  width: ${props =>
    props.imgWidth
      ? `calc(${props.imgWidth / props.imgHeight} * 30vw)`
      : "40vw"};
  height: 30vw;
  flex-shrink: 0;
  border-right: solid 20px #fff;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  img {
    object-fit: cover;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 75vw;
    display: none;
  }
`

const StyledImgMobile = styled(StyledImg)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    border-right: solid 10px #fff;

    display: inline-block;
    width: ${props =>
      props.imgWidth
        ? `calc(${props.imgWidth / props.imgHeight} * 50vw)`
        : "50vw"};
    height: 50vw;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  opacity: 0;
  transform: translate(0, -100%);
  transition: all 0.5s;
  width: ${props =>
    props.sliderWidth
      ? `calc(${props.sliderWidth}% - ${distances.mainBigMargin / 2}px )`
      : `calc(100% - ${distances.mainBigMargin}px )`};
  margin-left: ${props =>
    props.sliderWidth
      ? `calc(100% - ${props.sliderWidth}% - ${distances.mainBigMargin / 2}px )`
      : "0%"};
  .disable {
    opacity: 0.4;
    cursor: not-allowed;
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    opacity: 1;
    transform: translate(0, -0%);
    width: ${`calc(100% - ${distances.smallMargin}px)`};
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.darkGrey};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  transform: rotate(-45deg);
  transition: all 0.5s;
  height: 6px;
  width: 6px;
`

const ArrowHeadLeft = styled(ArrowHead)`
  transform: rotate(135deg) scale(1);
  transition: all 0.5s;
`

const ArrowHeadRight = styled(ArrowHead)`
  position: relative;
  left: -2px;
`

const ShowNext = styled.button`
  transition: all 0.5s;
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-right: 5px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkGrey};

  cursor: pointer;
  &.nondisable:hover {
    /* transform: translateX(10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadRight} {
    transform: translateX(5px) rotate(-45deg);
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &.nondisable:hover ${ArrowHeadRight} {
      transform: translateX(0px) rotate(-45deg);
      transition: all 0.5s;
    }
  }
`

const ShowPrev = styled.button`
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkGrey};

  cursor: pointer;

  &.nondisable:hover {
    /* transform: translateX(-10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadLeft} {
    transform: translateX(-5px) rotate(135deg);
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &.nondisable:hover ${ArrowHeadLeft} {
      transform: translateX(0px) rotate(135deg);
      transition: all 0.5s;
    }
  }
`
const SliderWrapper = styled(Wrapper)`
  height: auto;
  position: relative;
  /* width: calc(100vw - ${distances.mainBigMargin}px); */
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;

  :hover ${Credits} {
    transform: translate(0, calc(0% - 5px));
    transition: all 0.5s;
    opacity: 1;
  }
  :hover ${ControlWrapper} {
    transform: translate(0, calc(0% - 0px));
    transition: all 0.5s;
    opacity: 1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    overflow: visible;
    :hover ${Credits} {
      transform: translate(0, 100%);
      transition: all 0.5s;
      opacity: 1;
    }
  }

  /* margin-left: ${distances.mainBigMargin}px; */
`
class Slider extends React.Component {
  state = {
    x: 0,
    curW: 0,
    prevDisable: true,
    nextDisable: false,
    allWidthImg: [],
  }

  componentDidMount() {
    this.props.Gallery.map((document, index) => {
      this.setState(prevState => ({
        allWidthImg: [
          ...prevState.allWidthImg,
          document.width / document.height,
        ],
      }))
    })
  }

  changeSlideNext = () => {
    // this.setState({
    //   x: this.state.x + 1,
    //   curW: this.state.curW + this.state.allWidthImg[this.state.x],
    // })

    this.state.x >= this.props.Gallery.length - 1
      ? this.setState({
          x: this.props.Gallery.length - 1,
          curW: this.state.curW,
        })
      : this.setState({
          x: this.state.x + 1,
          curW: this.state.curW + this.state.allWidthImg[this.state.x],
        })

    this.state.x >= this.props.Gallery.length - 2
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })

    this.state.x >= 0
      ? this.setState({
          prevDisable: false,
        })
      : this.setState({
          prevDisable: true,
        })
  }

  changeSlidePrev = () => {
    this.state.x <= 0
      ? this.setState({
          x: 0,
          curW: 0,
        })
      : this.setState({
          x: this.state.x - 1,
          curW: this.state.curW - this.state.allWidthImg[this.state.x - 1],
        })

    this.state.x <= 1
      ? this.setState({
          prevDisable: true,
        })
      : this.setState({
          prevDisable: false,
        })

    this.state.x > this.props.Gallery.length - 1
      ? this.setState({
          nextDisable: true,
        })
      : this.setState({
          nextDisable: false,
        })
  }

  swipingLeft = e => {
    this.changeSlideNext()
    e.preventDefault()
  }
  swipingRight = e => {
    this.changeSlidePrev()
    e.preventDefault()
  }

  render() {
    return (
      <Swipeable
        onSwipedLeft={this.swipingLeft}
        preventDefaultTouchmoveEvent={true}
        onSwipedRight={this.swipingRight}
        preventDefaultTouchmoveEvent={true}
      >
        <SliderWrapper>
          {/* {console.log(this.state.curW + "curW")}
        {console.log(this.state.x + "___x")}
        {console.log(this.state.allWidthImg[this.state.x] + "___ratio")} */}
          <ImgWrapper sliderWidth={this.props.sliderWidth}>
            {this.props.Gallery.map((document, index) => {
              return (
                <figure>
                  <StyledImg
                    loading="eager"
                    imgWidth={document.width}
                    imgHeight={document.height}
                    key={index}
                    style={{
                      transform: `translateX(calc(${
                        this.state.curW
                      } * -30vw - ${this.state.x * 20}px))`,
                    }}
                    fluid={document.localFile.childImageSharp.fluid}
                  />
                  <StyledImgMobile
                    imgWidth={document.width}
                    imgHeight={document.height}
                    key={index}
                    style={{
                      transform: `translateX(calc(${
                        this.state.curW
                      } * -50vw - ${this.state.x * 10}px))`,
                    }}
                    fluid={document.localFile.childImageSharp.fluid}
                  />
                  <figcaption
                    style={{
                      transform: `translateX(calc(${
                        this.state.curW
                      } * -30vw - ${this.state.x * 20}px))`,
                    }}
                  >
                    {document.caption}
                  </figcaption>
                </figure>
              )
            })}
          </ImgWrapper>
          {this.props.credits ? (
            <Credits sliderWidth={this.props.sliderWidth}>
              {this.props.credits}
            </Credits>
          ) : null}
          <ControlWrapper sliderWidth={this.props.sliderWidth}>
            <ShowPrev
              className={this.state.prevDisable ? "disable" : "nondisable"}
              onClick={this.changeSlidePrev}
            >
              <ArrowHeadLeft />
              {/* pokaż wczesniej */}
            </ShowPrev>
            <ShowNext
              className={this.state.nextDisable ? "disable" : "nondisable"}
              onClick={this.changeSlideNext}
            >
              {/* pokaż dalej */}
              <ArrowHeadRight />
            </ShowNext>
          </ControlWrapper>
        </SliderWrapper>
      </Swipeable>
    )
  }
}

export default Slider
