import React from "react"
// import ReactMarkdown from "react-markdown"

import styled from "styled-components"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import ConditionDescription from "src/components/joinus/ConditionDescription"
import mediaQuery from "src/assets/styles/mediaQuery"

const ConditionsWrapper = styled.div`
  width: 66.66%;
  margin-left: 0%;
  height: 0;
  overflow: hidden;
  position: absolute;
  &.active {
    height: auto;
    position: relative;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`

const ConditionTitle = styled.button`
  font-size: ${typographySizes.xl}px;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

const ConditionPlusSign = styled.div`
  height: 30px;
  width: 30px;
  top: 8px;
  position: relative;
  margin-left: 50px;
`

const ConditionPlusSignVertical = styled.div`
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: ${colors.darkGrey};
  left: 50%;
  top: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  &.active {
    transform: translate(-50%, -50%) rotate(90deg) !important;

    transition: all 1s cubic-bezier(0.17, 0.67, 0, 2);
  }
`

const ConditionPlusSignHorizontal = styled.div`
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: ${colors.darkGrey};
  left: 50%;
  top: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
`
const Condition = styled.div`
  &:hover {
    ${ConditionPlusSignVertical} {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
`
class Conditions extends React.Component {
  state = { activeCompany: 0, activeCondition: null }

  render() {
    return (
      <>
        <ConditionsWrapper className={this.props.Active ? "active" : ""}>
          {this.props.Document.node.Recruitment_Condition.map(
            (document, index) => (
              <Condition>
                <ConditionTitle
                  onClick={() => {
                    if (this.state.activeCondition === index) {
                      this.setState({
                        activeCondition: null,
                      })
                    } else {
                      this.setState({
                        activeCondition: index,
                      })
                    }
                  }}
                >
                  <h3>{document.Title}</h3>
                  <ConditionPlusSign>
                    <ConditionPlusSignVertical
                      className={
                        index === this.state.activeCondition ? "active" : ""
                      }
                    />
                    <ConditionPlusSignHorizontal />
                  </ConditionPlusSign>
                </ConditionTitle>
                <ConditionDescription
                  Active={index === this.state.activeCondition ? true : false}
                  Description={document.Description}
                />
              </Condition>
            )
          )}
        </ConditionsWrapper>
      </>
    )
  }
}

export default Conditions
